import React from "react"
import Layout from "@components/Layout"
import SEO from "@components/SEO"
import MassachuettsPageContainer from "@components/MassachusettsPage"

function MassachusettsPage() {
  return (
    <Layout>
      <SEO title="Massachusetts" />
      <MassachuettsPageContainer />
    </Layout>
  )
}

export default MassachusettsPage
