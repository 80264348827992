import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import DescriptionCard from '@components/DescriptionCard'
import { Grid } from '@components/Grid'
import './MassachusettsPage.css'


function MassachuettsPage() {
  const data = useStaticQuery(graphql`
  query {
    gridImages: allFile(filter: { name: { regex: "/massachusetts-grid-*/" }, extension: { regex: "/(jpeg|jpg|gif|png)/" }, sourceInstanceName: { eq: "images"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  `)

  function getTitle() {
    return "Massachusetts"
  }

  function getDescription() {
    return <div>
      <p>Upon early retirement from nursing, and moving to Ma., Sara continued her studies in acting here at Cambridge School For Adult Education.  She was casted in "Mame" at the Westin Friendly Theatre, and as one of Mame's dearest friends, she had the opportunity to sing and dance in quite a few scenes throughout the play.  However, Sara's love has always been to sing with a band.. She joined the Newton Swing Band, and sang with them for a period of three years, at their weekly rehearsals, and also gigs that presented themselves throughout the years at a variety of venues.</p>

      <p>Sara then opened up her own business  “Musical Showcases,” which evolved into different band scenarios depending on the event.  She has been featured at Ryles Jazz Café,  Springstep in Medford, and Amazing  Things Art Center  in BACA’s Cabaret Festivals….as well as being featured three times at Amazing Things Art Center ‘s monthly cabaret shows..  Sara has put together a number of shows over the last eight years in the Greater Boston and Metrowest area through her own business.  the shows are tailored to whatever the venue has requested.</p>

      <p>As "Outreach Director for Boston Association of Cabaret Artists since 2012, Sara has also been able to bring shows to a variety of venues, where members enjoy the opportunity to sing along with the accompaniment of a pianist.</p>

      <p>It is Sara's pleasure to meet up with entertainers in the musical community to share time and talent.</p>
    </div>
  }

  return (
    <div className="collection-page-container">

      <div className="card">
        <DescriptionCard
          longDescription
          noButton
          title={getTitle()}
          description={getDescription()}
        />
      </div>

      <div className="image-grid">
        <Grid items={data.gridImages.edges} />
      </div>

    </div>
  )
}

export default MassachuettsPage